import gql from 'graphql-tag'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { CbtExamMessage_EmbedDataFragment } from 'generated/graphql'

gql`
  fragment CbtExamMessage_embedData on EmbedData {
    embedType
    title
    subtitle
    content
    url
    urlText
  }
`

interface CbtExamMessageProps {
  embedData: CbtExamMessage_EmbedDataFragment
  viewrIsSender: boolean
}

const CbtExamMessage = ({ embedData, viewrIsSender }: CbtExamMessageProps) => {
  return (
    <StyledWrapper className={viewrIsSender ? 'sender' : ''}>
      <strong>{embedData.title}</strong>
      <p className="embed-subtitle">[{embedData.subtitle}]</p>
      <span>{embedData.content}</span>
      <Link href={embedData.url || ''}>
        <a>{embedData.urlText}</a>
      </Link>
    </StyledWrapper>
  )
}

export default CbtExamMessage

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 225px;
    border-radius: 8px;
    background-color: #f3f4f8;
    padding: 10px 12px;

    &.sender {
      background-color: #eaf8ff;
    }

    & > strong {
      width: 100%;
      height: 29px;
      font-size: 13px;
      font-weight: bold;
      line-height: 19px;
      letter-spacing: -0.56px;
      text-align: left;
      color: ${theme.color.text_1};
      border-bottom: 1px solid ${theme.line.line_1};
      display: block;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    & > .embed-subtitle {
      width: 100%;
      min-height: 22px;
      font-size: 15px;
      font-weight: 500;
      line-height: 22px;
      color: ${theme.color.text_1};
      margin-bottom: 4px;
    }

    & > span {
      width: 100%;
      font-size: 15px;
      font-weight: normal;
      word-break: break-all;
      color: ${theme.color.text_1};
      display: block;
      margin-bottom: 15px;
    }

    & > a {
      width: 100%;
      height: 40px;
      font-size: 13px;
      font-weight: bold;
      line-height: 40px;
      text-align: center;
      color: #ffffff;
      border-radius: 8px;
      background-color: ${theme.color.primary_2};
      display: block;
    }
  `}
`
